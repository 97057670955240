import request from '@/utils/request'

// 获取热搜词列表
export function  getHotSeatchList (data) {
    return request({
        url: '/customer-service/lrHotWord/queryLrHotWord',
        method: 'post',
        data
    })
}

// 根据id获取热搜词详情
export function getHotSeatchrDetail (id) {
    return request({
        url:  `customer-service/lrHotWord/queryLrHotWordDetail?id=${id}`,
        method: 'get',
    })
}

// 根据id删除热搜词
export function hotSeatchDelete (id) {
    return request({
        url: `/customer-service/lrHotWord/delLrHotWord?id=${id}`,
        method: 'delete'
    })
}


// 提交新增、编辑热搜词数据
export function subHotSeatchForm (data) {
    return request({
        url: '/customer-service/lrHotWord/addOrUpdateLrHotWord',
        method: "post",
        data
    })
}