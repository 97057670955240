<template>
  <div>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <el-button
                class="ml-15"
                type="primary"
                @click="showBannerDialog('add')"
                >新增热搜词</el-button
              >
            </div>
          </div>

          <!-- 门店列表 -->
          <el-tabs v-model="storeId" @tab-click="changeTab" class="mt-20">
            <el-tab-pane
              :label="item.storeName"
              :name="item.id + ''"
              v-for="(item, index) in storeList"
              :key="index"
            >
            </el-tab-pane>
          </el-tabs>

          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="标签" prop="words"></el-table-column>
            <el-table-column label="排序" prop="sortNum"></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                {{ scope.row.status === 1 ? "显示" : "不显示" }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <span
                    @click="showBannerDialog('edit', scope.row.id)"
                    class="tip-text-edit"
                  >
                    <i class="el-icon-edit-outline"></i>
                    编辑
                  </span>
                  <span
                    class="ml-10 tip-text-delete"
                    @click="deleteDate(scope.row.id)"
                  >
                    <i class="el-icon-delete"></i>
                    删除
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="search"
            @syncPageData="syncPageData"
          ></Pagination>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="showBanner"
      :title="opeType == 'add' ? '添加热搜词' : '编辑热搜词'"
      @close="clearDialog"
    >
      <div class="pad-container">
        <el-form
          class="mt-24"
          label-width="100px"
          :model="form"
          :rules="rules"
          ref="form"
        >
          <el-form-item label="标签" prop="words">
            <el-input
              placeholder="请输入标签"
              v-model="form.words"
              class="w-100"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sortNum">
            <el-input
              placeholder="请输入排序"
              v-model="form.sortNum"
              class="w-100"
            ></el-input>
          </el-form-item>
          <el-form-item label="选择门店" prop="storeId">
            <el-select
              placeholder="选择所属门店"
              v-model="form.storeId"
              class="w-100"
            >
              <el-option
                v-for="(item, index) in storeList"
                :key="index"
                :value="item.id"
                :label="item.storeName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否显示" prop="status">
            <el-radio v-model="form.status" :label="1">显示</el-radio>
            <el-radio v-model="form.status" :label="0">不显示</el-radio>
          </el-form-item>
        </el-form>
        <div class="flx-row ali-c js-fe mt-50">
          <el-button
            plain
            size="small"
            class="form-button-w70"
            @click="showBanner = false"
            >取消</el-button
          >
          <el-button
            class="form-button-w70"
            size="small"
            type="primary"
            @click="submit"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import { getStoreList } from "@/api/online_department_store/commodity_manage";
import {
  getHotSeatchList,
  getHotSeatchrDetail,
  hotSeatchDelete,
  subHotSeatchForm,
} from "@/api/config_center/miniprogram_hotSearch";
export default {
  components: { Pagination },
  data() {
    return {
      // 顶部搜索栏数据
      keyword: "",
      // 会员表格数据内容
      tableData: [],
      showBanner: false,
      opeType: "",
      form: {
        words: "",
        sortNum: "",
        status: "",
        storeId: "",
      },
      rules: {
        words: [{ required: true, message: "标签不能为空", trigger: "blur" }],
        sortNum: [
          { required: true, message: "排序不能为空" },
          {
            pattern: /^[1-9]\d*$/,
            message: "大于0的正整数",
            trigger: "change",
          },
        ],
        storeId: [{ required: true, message: "门店不能为空", trigger: "blur" }],
        status: [{ required: true }],
      },
      storeList: [],
      storeId: "",
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  mounted() {
    this.getStores();
  },
  methods: {
    // 搜索事件
    search(type) {
      if (type == 1) {
        this.pageNum = 1;
        this.pageSize = 10;
      }

      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        storeId: this.storeId,
      };
      getHotSeatchList(params).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.body.total;
          this.tableData = res.data.body.list;
        }
      });
    },
    // 重置搜索关键词
    reset() {
      this.keyword = "";
      this.search(1);
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    // 切换tab
    changeTab(tab, event) {
      this.storeId = tab.name;
      this.search(1);
    },
    // 获取门店列表
    getStores() {
      getStoreList().then((res) => {
        this.storeList = res.data.body.list;
        this.storeId = res.data.body.list[0].id + "";
        this.search(1);
      });
    },
    //根据id获取热搜词详情
    getDetail(id) {
      getHotSeatchrDetail(id).then((res) => {
        this.form = res.data.body;
      });
    },
    showBannerDialog(type, id) {
      this.showBanner = true;
      this.opeType = type;
      if (id != undefined && id != "undefined") {
        this.getDetail(id);
      }
    },
    clearDialog() {
      this.form = {
        words: "",
        sortNum: "",
        status: "",
        storeId: "",
      };
    },
    deleteDate(id) {
      this.$confirm("此操作将永久删除该热搜词, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          hotSeatchDelete(id).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.search();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submitForm();
        } else {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning",
          });
        }
      });
    },
    submitForm() {
      let data = this.form;
      subHotSeatchForm(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.showBanner = false;
          this.clearDialog();
          this.search();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.tip-text-edit {
  color: #409eff;
  cursor: pointer;
}
</style>
